import React from 'react'
import { Link } from 'gatsby'

import { rhythm, scale } from '../utils/typography'

const commonHeaderStyle = {
  marginTop: 0,
};

const rootHeaderStyle = {
  ...commonHeaderStyle,
  marginBottom: rhythm(1.5),
};

const secondaryHeaderStyle = {
  ...commonHeaderStyle,
  marginBottom: rhythm(-1),
};

const Header = ({ title, isRoot }) => {
  const style = isRoot ? rootHeaderStyle : secondaryHeaderStyle;
  const titleComponent = isRoot ? 'h1' : 'h3';
  const link = <Link
        style={{
          boxShadow: `none`,
          textDecoration: `none`,
          color: `inherit`,
        }}
        to={`/`}
      >
        {title}
      </Link>

  return React.createElement(titleComponent, { style }, link);
}

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    const isRoot = location.pathname === rootPath;

    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <Header title={title} isRoot={isRoot} />
        {children}
        <footer>
          © 2019, Kilian Cirera Sant
        </footer>
      </div>
    )
  }
}

export default Layout
