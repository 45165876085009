import Typography from 'typography'
import Wordpress2016 from 'typography-theme-wordpress-2016'

Wordpress2016.overrideThemeStyles = () => {
  return {
    'body': {
      'background-color': '#f4f4f4',
      'border-top': '5px solid #c50000',
    },
    'a': {
      color: '#b00000',
    },
    'a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
    'h1': {
      color: '#c50000'
    },
    'h1, h2, h3': {
      fontWeight: '200',
    },
    'code': {
      fontSize: '0.85em',
    }
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
